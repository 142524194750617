import cloneDeep from 'lodash-es/cloneDeep';

const routeMapper = {
    products: {
        base: '/products',
        studio: '/products/studio',
        fabric: '/products/fabric',
        cloud: '/products/cloud',
        Ateliersimulator: '/products/ateliersimulator',
        market: '/products/market',
        one: '/products/one',
        atelier: '/products/atelier',
        simulator: '/products/simulator',
        mixmatch: '/products/mixmatch',
        ai: '/products/ai',
        knit: '/products/knit',
    },
    solutions: {
        base: '/solutions',
        brand: '/solutions/brand',
        manufacturer: '/solutions/manufacturer',
        fabric: '/solutions/fabric',
        academy: '/solutions/academy',
        virtualfashion: '/solutions/virtualfashion',
    },
    support: {
        base: '/support',
        contentservices: '/support/contentservices',
        customersuccess: '/support/customersuccess',
        consultationservice: '/support/consultationservice',
        help: '/support/help',
        case: '/support/case',
    },
    // resource: {
    //     base: '/resource',
    //     learning: '/resource/learning',
    //     // case: '/resource/case',
    //     // news: '/resource/news',
    //     // olympic: '/resource/olympic',
    // },
    aboutus: {
        base: '/aboutus',
        introduction: '/aboutus/introduction',
        responsibility: '/aboutus/responsibility',
        partners: '/aboutus/partners',
        fellowship: '/aboutus/fellowship',
        news: '/aboutus/news',
    },
    protocol: { base: '/protocol', privacypolicy: '/protocol/privacypolicy', cookie: '/protocol/cookie' },
};

// 组合样式 映射subMenu 一维数组，目前只有产品菜单需要
const NAV_MAPPER_GROUP_MAPPER_EN_PC = {
    [routeMapper.products.studio]: [
        { title: 'For Fashion', slice: [0, 4] },
        { title: 'For CG', slice: [4, 6] },
        { title: 'AI', slice: [7, 8] },
    ],
    [routeMapper.aboutus.introduction]: [
        { title: 'Our Company', slice: [0, 3] },
        { title: 'Events', slice: [3, 5] },
        { title: 'Newsroom', slice: [5, 7] },
    ],
};

// 完整页面Mapper
const NAV_MAPPER_EN_PC = [
    {
        title: 'global_home_header_menu_0',
        routePath: routeMapper.products.studio,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_0_0',
                des: 'global_home_header_submenu_link_0_0_des',
                mini_dex: '',
                routePath: routeMapper.products.studio,
            },

            {
                linkText: 'global_home_header_submenu_link_0_1',
                des: 'global_home_header_submenu_link_0_1_des',
                mini_dex: '',
                routePath: routeMapper.products.fabric,
            },

            {
                linkText: 'global_home_header_submenu_link_0_8',
                des: 'global_home_header_submenu_link_0_8_des',
                mini_dex: '',
                routePath: routeMapper.products.mixmatch,
            },
            {
                linkText: 'global_home_header_submenu_link_0_2',
                des: 'global_home_header_submenu_link_0_2_des',
                mini_dex: '',
                routePath: routeMapper.products.cloud,
            },
            {
                linkText: 'global_home_header_submenu_link_0_5',
                des: 'global_home_header_submenu_link_0_5_des',
                mini_dex: '',
                routePath: {
                    'zh-CN': 'https://atelier.style3d.com/zh-CN/home',
                    en: 'https://atelier.style3d.com/en/home',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_0_6',
                des: 'global_home_header_submenu_link_0_6_des',
                mini_dex: '',
                routePath: {
                    'zh-CN': 'https://simulator.style3d.com/zh-CN/home',
                    en: 'https://simulator.style3d.com/en/home',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_0_7',
                des: 'global_home_header_submenu_link_0_7_des',
                mini_dex: '',
                routePath: routeMapper.products.one,
            },
            {
                linkText: 'Style3D Ai',
                des: 'global_home_header_submenu_link_0_6_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.ai,
            },
        ],
    },
    {
        title: 'global_home_header_menu_1',
        routePath: routeMapper.solutions.brand,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_1_0',
                linkTextLong: 'global_home_header_submenu_link_long_1_0',
                des: 'global_home_header_submenu_link_1_0_des',
                mini_dex: 'global_home_header_submenu_link_1_0_des2',
                routePath: routeMapper.solutions.brand,
            },
            {
                linkText: 'global_home_header_submenu_link_1_1',
                linkTextLong: 'global_home_header_submenu_link_long_1_1',
                des: 'global_home_header_submenu_link_1_1_des',
                mini_dex: 'global_home_header_submenu_link_1_1_des2',
                routePath: routeMapper.solutions.manufacturer,
            },
            {
                linkText: 'global_home_header_submenu_link_1_2',
                linkTextLong: 'global_home_header_submenu_link_long_1_2',
                des: 'global_home_header_submenu_link_1_2_des',
                mini_dex: 'global_home_header_submenu_link_1_2_des2',
                routePath: routeMapper.solutions.fabric,
            },
            {
                linkText: 'global_home_header_submenu_link_1_3',
                linkTextLong: 'global_home_header_submenu_link_long_1_3',
                des: 'global_home_header_submenu_link_1_3_des',
                mini_dex: 'global_home_header_submenu_link_1_3_des2',
                routePath: routeMapper.solutions.academy,
            },
            // {
            //     linkText: 'global_home_header_submenu_link_1_4',
            //     des: 'global_home_header_submenu_link_1_4_des',
            //     mini_dex: 'global_home_header_submenu_link_1_4_des2',
            //     routePath: routeMapper.solutions.virtualfashion,
            // },
        ],
    },
    {
        title: 'global_home_header_submenu_link_2_1',
        routePath: routeMapper.support.consultationservice,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_2_3',
                des: 'global_home_header_submenu_link_2_3_des',
                mini_dex: 'global_home_header_submenu_link_2_3_des2',
                routePath: routeMapper.support.consultationservice,
            },
            {
                linkText: 'global_home_header_submenu_link_2_4',
                des: 'global_home_header_submenu_link_2_1_des',
                mini_dex: 'global_home_header_submenu_link_2_1_des2',
                routePath: routeMapper.support.customersuccess,
            },
            {
                linkText: 'global_home_header_submenu_link_2_0',
                des: 'global_home_header_submenu_link_2_0_des',
                mini_dex: 'global_home_header_submenu_link_2_0_des2',
                routePath: routeMapper.support.contentservices,
            },
            {
                linkText: 'global_home_header_submenu_link_3_5',
                des: 'global_home_header_submenu_link_3_5_des',
                mini_dex: 'global_home_header_submenu_link_3_5_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/customerstories',
                    en: 'https://enresource.linctex.com/customerstories',
                },
            },
        ],
    },
    {
        title: 'global_home_header_menu_4',
        routePath: routeMapper.aboutus.introduction,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_4_0',
                // des: 'global_home_header_submenu_link_4_0_des',
                mini_dex: 'global_home_header_submenu_link_4_0_des2',
                routePath: routeMapper.aboutus.introduction,
            },
            {
                linkText: 'global_home_header_submenu_link_4_1',
                linkTextLong: 'global_home_header_submenu_link_long_4_1',
                // des: 'global_home_header_submenu_link_4_1_des',
                mini_dex: 'global_home_header_submenu_link_4_1_des2',
                routePath: routeMapper.aboutus.responsibility,
            },
            {
                linkText: 'global_home_header_submenu_link_3_1',
                // des: 'global_home_header_submenu_link_3_1_des',
                mini_dex: 'global_home_header_submenu_link_3_1_des2',
                routePath: routeMapper.aboutus.partners,
            },
            {
                linkText: 'global_home_header_submenu_link_3_6',
                // des: 'global_home_header_submenu_link_3_6_des',
                mini_dex: 'global_home_header_submenu_link_3_6_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/copy-2-of-projects-4-1',
                    en: 'https://enresource.linctex.com/events',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_4_2',
                // des: 'global_home_header_submenu_link_4_2_des',
                mini_dex: 'global_home_header_submenu_link_4_2_des2',
                routePath: routeMapper.aboutus.fellowship,
            },
            {
                linkText: 'global_home_header_submenu_link_3_3',
                // des: 'global_home_header_submenu_link_3_3_des',
                mini_dex: 'global_home_header_submenu_link_3_3_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/news',
                    en: 'https://enresource.linctex.com/news',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_3_4',
                // des: 'global_home_header_submenu_link_3_4_des',
                mini_dex: 'global_home_header_submenu_link_3_4_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/blog',
                    en: 'https://enresource.linctex.com/blog',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_3_8',
                // des: 'global_home_header_submenu_link_3_8_des',
                mini_dex: 'global_home_header_submenu_link_3_8_des2',
                routePath: {
                    'zh-CN': 'https://www.style3d.com/public/helper/index',
                    en: 'https://www.style3d.com/public/helper/index?lang=en',
                },
            },
        ],
    },
];

const NAV_MAPPER_GROUP_MAPPER_CN_PC = {
    [routeMapper.products.studio]: [
        { title: '时尚产品', slice: [0, 5] },
        { title: 'CG产品', slice: [5, 7] },
        { title: 'AI技术', slice: [7, 8] },
    ],
    [routeMapper.aboutus.introduction]: [
        { title: '企业介绍', slice: [0, 3] },
        { title: '企业活动', slice: [3, 5] },
        { title: '企业新闻', slice: [5, 7] },
    ],
};

// 完整页面Mapper
const NAV_MAPPER_CN_PC = [
    {
        title: 'global_home_header_menu_0',
        routePath: routeMapper.products.studio,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_0_0',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_0_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.studio,
            },
            {
                linkText: 'global_home_header_submenu_link_0_1',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_1_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.fabric,
            },
            {
                linkText: 'global_home_header_submenu_link_0_8',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_2_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.mixmatch,
            },
            {
                linkText: 'Style3D Knit',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_7_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.knit,
            },
            {
                linkText: 'global_home_header_submenu_link_0_2',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_3_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.cloud,
            },
            {
                linkText: 'global_home_header_submenu_link_0_5',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_4_pc_des',
                mini_dex: '',
                routePath: {
                    'zh-CN': 'https://atelier.style3d.com/zh-CN/home',
                    en: 'https://atelier.style3d.com/en/home',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_0_6',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_5_pc_des',
                mini_dex: '',
                routePath: {
                    'zh-CN': 'https://simulator.style3d.com/zh-CN/home',
                    en: 'https://simulator.style3d.com/en/home',
                },
            },
            {
                linkText: 'Style3D Ai',
                linkTextIsEn: true,
                des: 'global_home_header_submenu_link_0_6_pc_des',
                mini_dex: '',
                routePath: routeMapper.products.ai,
            },
            // {
            //     linkText: 'global_home_header_submenu_link_0_7',
            //     des: 'global_home_header_submenu_link_0_7_des',
            //     mini_dex: 'global_home_header_submenu_link_0_7_des3',
            //     routePath: routeMapper.products.one,
            // },
        ],
    },
    {
        title: 'global_home_header_menu_1',
        routePath: routeMapper.solutions.brand,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_1_0',
                linkTextLong: 'global_home_header_submenu_link_long_1_0',
                des: 'global_home_header_submenu_link_1_0_des',
                mini_dex: 'global_home_header_submenu_link_1_0_des2',
                routePath: routeMapper.solutions.brand,
            },
            {
                linkText: 'global_home_header_submenu_link_1_1',
                linkTextLong: 'global_home_header_submenu_link_long_1_1',
                des: 'global_home_header_submenu_link_1_1_des',
                mini_dex: 'global_home_header_submenu_link_1_1_des2',
                routePath: routeMapper.solutions.manufacturer,
            },
            {
                linkText: 'global_home_header_submenu_link_1_2',
                linkTextLong: 'global_home_header_submenu_link_long_1_2',
                des: 'global_home_header_submenu_link_1_2_des',
                mini_dex: 'global_home_header_submenu_link_1_2_des2',
                routePath: routeMapper.solutions.fabric,
            },
            {
                linkText: 'global_home_header_submenu_link_1_3',
                linkTextLong: 'global_home_header_submenu_link_long_1_3',
                des: 'global_home_header_submenu_link_1_3_des',
                mini_dex: 'global_home_header_submenu_link_1_3_des2',
                routePath: routeMapper.solutions.academy,
            },
            // {
            //     linkText: 'global_home_header_submenu_link_1_4',
            //     des: 'global_home_header_submenu_link_1_4_des',
            //     mini_dex: 'global_home_header_submenu_link_1_4_des2',
            //     routePath: routeMapper.solutions.virtualfashion,
            // },
        ],
    },
    {
        title: 'global_home_header_submenu_link_2_1',
        routePath: routeMapper.support.consultationservice,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_2_3',
                des: 'global_home_header_submenu_link_2_3_des',
                mini_dex: 'global_home_header_submenu_link_2_3_des2',
                routePath: routeMapper.support.consultationservice,
            },
            {
                linkText: 'global_home_header_submenu_link_2_4',
                des: 'global_home_header_submenu_link_2_1_des',
                mini_dex: 'global_home_header_submenu_link_2_1_des2',
                routePath: routeMapper.support.customersuccess,
            },
            {
                linkText: 'global_home_header_submenu_link_2_0',
                des: 'global_home_header_submenu_link_2_0_des',
                mini_dex: 'global_home_header_submenu_link_2_0_des2',
                routePath: routeMapper.support.contentservices,
            },
            {
                linkText: 'global_home_header_submenu_link_3_5',
                des: 'global_home_header_submenu_link_3_5_des',
                mini_dex: 'global_home_header_submenu_link_3_5_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/customerstories',
                    en: 'https://enresource.linctex.com/customerstories',
                },
            },
        ],
    },

    {
        title: 'global_home_header_menu_4',
        routePath: routeMapper.aboutus.introduction,
        submenu: [
            {
                linkText: 'global_home_header_submenu_link_4_0',
                // des: 'global_home_header_submenu_link_4_0_des',
                mini_dex: 'global_home_header_submenu_link_4_0_des2',
                routePath: routeMapper.aboutus.introduction,
            },
            {
                linkText: 'global_home_header_submenu_link_4_1',
                linkTextLong: 'global_home_header_submenu_link_long_4_1',
                // des: 'global_home_header_submenu_link_4_1_des',
                mini_dex: 'global_home_header_submenu_link_4_1_des2',
                routePath: routeMapper.aboutus.responsibility,
            },
            {
                linkText: 'global_home_header_submenu_link_3_1',
                // des: 'global_home_header_submenu_link_3_1_des',
                mini_dex: 'global_home_header_submenu_link_3_1_des2',
                routePath: routeMapper.aboutus.partners,
            },
            {
                linkText: 'global_home_header_submenu_link_3_6',
                // des: 'global_home_header_submenu_link_3_6_des',
                mini_dex: 'global_home_header_submenu_link_3_6_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/copy-2-of-projects-4-1',
                    en: 'https://enresource.linctex.com/events',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_4_2',
                // des: 'global_home_header_submenu_link_4_2_des',
                mini_dex: 'global_home_header_submenu_link_4_2_des2',
                routePath: routeMapper.aboutus.fellowship,
            },
            {
                linkText: 'global_home_header_submenu_link_3_3',
                // des: 'global_home_header_submenu_link_3_3_des',
                mini_dex: 'global_home_header_submenu_link_3_3_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/news',
                    en: 'https://enresource.linctex.com/news',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_3_4',
                // des: 'global_home_header_submenu_link_3_4_des',
                mini_dex: 'global_home_header_submenu_link_3_4_des2',
                routePath: {
                    'zh-CN': 'https://zhresource.linctex.com/blog',
                    en: 'https://enresource.linctex.com/blog',
                },
            },
            {
                linkText: 'global_home_header_submenu_link_3_8',
                // des: 'global_home_header_submenu_link_3_8_des',
                mini_dex: 'global_home_header_submenu_link_3_8_des2',
                routePath: {
                    'zh-CN': 'https://www.style3d.com/public/helper/index',
                    en: 'https://www.style3d.com/public/helper/index?lang=en',
                },
            },
        ],
    },
];

const localeProcess = (navMapperList: Array<any>, locale: string) => {
    // 遍历navMapperList，列表对象为element
    navMapperList.forEach((element) => {
        const keySet = Object.keys(element);
        for (let i = 0; i < keySet.length; i++) {
            const key = keySet[i];
            const item = element[key];
            // console.log("key === 'submenu'", i, item, key, key === 'submenu');

            // 获取对应locale的值,并进行替换
            if (typeof item === 'object' && Object.keys(item).includes(locale)) element[key] = element[key][locale];

            // 对数组类型的属性重复调用此函数
            if (Array.isArray(item)) {
                localeProcess(item, locale);
            }
        }
    });
};

// 调用lodash的cloneDeep方法，深拷贝
// const NAV_MAPPER_EN = cloneDeep(NAV_MAPPER);
// const NAV_MAPPER_CN = cloneDeep(NAV_MAPPER);

localeProcess(NAV_MAPPER_EN_PC, 'en');
localeProcess(NAV_MAPPER_CN_PC, 'zh-CN');

export {
    NAV_MAPPER_CN_PC,
    NAV_MAPPER_GROUP_MAPPER_CN_PC,
    NAV_MAPPER_EN_PC,
    NAV_MAPPER_GROUP_MAPPER_EN_PC,
    routeMapper,
};
